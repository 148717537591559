import React, { useState, useEffect, useRef } from 'react';
import './Carousel.css';

const Carousel = ({ images, interval = 1500 }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsPerSlide, setItemsPerSlide] = useState(5);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const carouselRef = useRef(null);
    const transitionRef = useRef(null);
    const totalItems = images.length;

    const updateItemsPerSlide = () => {
        const width = window.innerWidth;
        if (width >= 1200) {
            setItemsPerSlide(5);
        } else if (width >= 992) {
            setItemsPerSlide(4);
        } else if (width >= 768) {
            setItemsPerSlide(3);
        } else {
            setItemsPerSlide(1);
        }
    };

    useEffect(() => {
        updateItemsPerSlide(); // Set initial itemsPerSlide
        window.addEventListener('resize', updateItemsPerSlide);

        return () => {
            window.removeEventListener('resize', updateItemsPerSlide);
        };
    }, []);

    useEffect(() => {
        const handleTransitionEnd = () => {
            if (currentIndex === totalItems) {
                transitionRef.current.style.transition = 'none';
                setCurrentIndex(0);
            } else if (currentIndex === -1) {
                transitionRef.current.style.transition = 'none';
                setCurrentIndex(totalItems - 1);
            }
            setIsTransitioning(false);
        };

        const node = transitionRef.current;
        if (node) {
            node.addEventListener('transitionend', handleTransitionEnd);
        }

        const intervalId = setInterval(() => {
            setIsTransitioning(true);
            setCurrentIndex((prevIndex) => (prevIndex + 1) % (totalItems + 1));
        }, interval);

        return () => {
            clearInterval(intervalId);
            if (node) {
                node.removeEventListener('transitionend', handleTransitionEnd);
            }
        };
    }, [totalItems, interval, currentIndex]);

    useEffect(() => {
        if (isTransitioning) {
            transitionRef.current.style.transition = 'transform 0.5s ease-in-out';
        } else {
            transitionRef.current.style.transition = 'none';
        }
    }, [isTransitioning]);

    return (
        <div className="carousel" ref={carouselRef}>
            <div
                className="carousel-track"
                ref={transitionRef}
                style={{ transform: `translateX(-${(currentIndex * 100) / itemsPerSlide}%)` }}
            >
                {images.concat(images.slice(0, itemsPerSlide)).map((image, index) => (
                    <div
                        className="carousel-item"
                        key={index}
                        style={{ flex: `0 0 ${100 / itemsPerSlide}%` }}
                    >
                        <img src={image} alt={`Slide ${index}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Carousel;
