import { useTranslation } from 'react-i18next';
import './FooterSocials.css';
import logo from './logo-white2.png';
import { FaFacebookF } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";


const FooterSocials = () => {
    const { t } = useTranslation(); // useTranslation hook to get translations

    return (
        <div className='footer-socials-wrapper'>
            <div className='logo-footer-wrapper'>
                <img src={logo} alt='NexGen' />
            </div>
            <span>{t('socials.title')}</span>
            <div className='socials-wrapper'>
                <div className='socials-wrapper-icon'>
                    <a href='' target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
                </div>  
                <div className='socials-wrapper-icon'>
                    <a href='https://www.instagram.com/nexgensolutions.pt/' target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                </div>  
                <div className='socials-wrapper-icon'>
                    <a href='' target="_blank" rel="noopener noreferrer"><FaTiktok /></a>
                </div>  
            </div>
        </div>
    )
}

export default FooterSocials;