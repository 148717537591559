import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer/Footer';
import FooterSocials from '../../components/FooterSocials/FooterSocials';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import './Contact.css';
import ContactForm from './ContactForm/ContactForm';
import { useEffect } from 'react';

const Contact = () => {
    const { t } = useTranslation(); // useTranslation hook to get translations

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <SectionTitle title={t('contact.title2')} backgroundImage="/background/contact2.jpg" />
            <div className='contact-header'>    
                <p className='contact-header-title'>{t('contact.journey')}</p>
                <p className='contact-header-text'>
                {t('contact.journeyDesc')}
                </p>
            </div>
            <ContactForm />
            <FooterSocials />
            <Footer />
        </div>
    )
}

export default Contact;