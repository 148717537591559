import { useTranslation } from 'react-i18next';
import './AboutContent.css';
import logo from './logo-white-without-name.png';

const AboutContent = () => {
    const { t } = useTranslation();
    return (
        <div className='about-content-wrapper'>
            <div className='about-content-img'>
                <img src={logo} alt='img company' />
            </div>
            <div className='about-content-text'>
                <span className='about-content-text-title'>
                    {t('about.titleDesc')}
                </span>
                <span className='about-content-text-text'>
                    {t('about.text1')}
                </span>
                <span className='about-content-text-text'>
                    {t('about.text2')}
                </span>
            </div>
        </div>
    )
}

export default AboutContent;