import React from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { DarkModeProvider } from './context/DarkModeContext/darkModeContext';
import HeroSection from './views/HeroSection/HeroSection';
import Services from './views/Services/Services';
import About from './views/About/About';
import Contact from './views/Contact/Contact';
import Products from './views/Products/Products';
import ReactGA from 'react-ga4';

function App() {

  ReactGA.initialize('G-8XLE5JQ8E9');
  ReactGA.send("pageview");

  return (
    <Router>
      <DarkModeProvider>
        <Navbar />
        <Routes>
          <Route path="/" element={<HeroSection />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </DarkModeProvider>
    </Router>
  );
}

export default App;
