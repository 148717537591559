// data.js

import product from './seo-ebook.png';
import product2 from './ads-ebook.png';
import product3 from './google-ads-ebook.png';

const products = [
    {
        id: 1,
        name: 'SEO Mastery',
        price: '24.99€',
        image: product,
        originalPrice: '34.99€',  // Discounted product
        available: false,  // Now product is available
        redirectLink: 'https://pay.hotmart.com/H95426590S', // Add the redirect link here
    },
    {
        id: 2,
        name: 'Facebook/Instagram Ads',
        price: '19.99€',
        image: product2,
        originalPrice: '29.99€',  // Discounted product
        available: false,  // Product is not available
        redirectLink: '', // Redirect link
    },
    {
        id: 3,
        name: 'Google Ads Guide',
        price: '24.99€',
        image: product3,
        originalPrice: null,  // No discount
        available: false,  // Product is not available
        redirectLink: '', // Redirect link
    },
];

export default products;
