import Footer from '../../components/Footer/Footer';
import FooterSocials from '../../components/FooterSocials/FooterSocials';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import './Products.css';
import OutlineButton from '../../components/OutlineButton/OutlineButton';
import products from './data';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const Products = () => {
    const { t } = useTranslation(); // useTranslation hook to get translations

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleOrderClick = (redirectLink) => {
        // Open the redirect link in a new tab/window
        window.open(redirectLink, '_blank');
    };

    return (
        <div>
            <SectionTitle title={t('products.title')} backgroundImage="/background/library2.jpg" />
            <div className='products-wrapper'>
                <div className='contact-header'>
                    <p className='contact-header-title'>{t('products.productsTitle')}</p>
                    <p className='contact-header-text' style={{ marginBottom: '2rem' }}>
                        {t('products.productsDesc')}
                    </p>
                </div>
                <div className='products-list-wrapper'>
                    {products.map(product => (
                        <div className='product-item' key={product.id}>
                            <div className='product-item-img'>
                                <img src={product.image} alt={product.name} />
                            </div>
                            <div className='product-item-content'>
                                <div>
                                    <p className='product-name'>{product.name}</p>
                                    {product.available && (
                                        <div className='product-price-wrapper'>
                                            {product.originalPrice && (
                                                <p className='product-original-price'>
                                                    <s>{product.originalPrice}</s>
                                                </p>
                                            )}
                                            <p className='product-price'>
                                                {product.price}
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className='order-button-wrapper'>
                                    {product.available ? (
                                        <OutlineButton
                                            arrow
                                            onClick={() => handleOrderClick(product.redirectLink)} // Use the redirectLink from data
                                        >
                                            {t('products.order')}
                                        </OutlineButton>
                                    ) : (
                                        <p className='available-soon'>{t('products.availableSoon')}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <FooterSocials />
            <Footer />
        </div>
    );
}

export default Products;

