import { useTranslation } from 'react-i18next';
import './ServicesItem.css';

const ServicesItem = () => {
    const { t } = useTranslation(); // useTranslation hook to get translations

    return (
        <div className="services-item">
            <div className='services-item-wrapper'>
                <p className="services-item-title">{t('services.header')}</p>
                <p className="services-item-text">
                {t('services.headerDesc')}
                    </p>
            </div>
            <div className='services-item-list'>
                <p>Branding</p>
                <p>Consulting</p>
                <p>Social Media</p>
                <p>Packaging</p>
                <p>Web Design</p>
                <p>Public Relations</p>
                {/*<p>Signage</p>*/}
                <p>Flyer Design</p>
                <p>Ecommerce</p>
                <p>Digital Ads</p>
            </div>
        </div>
    );
}

export default ServicesItem;
