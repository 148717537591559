import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer/Footer';
import FooterSocials from '../../components/FooterSocials/FooterSocials';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import ServicesItem from './sections/ServicesItems/ServicesItem';
import './Services.css';
import { useEffect } from 'react';

const Services = () => {
    const { t } = useTranslation(); // useTranslation hook to get translations

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='services-wrapper'>
            <SectionTitle title={t('services.title')} backgroundImage="/background/work34.jpg" />
            <ServicesItem />
            <FooterSocials />
            <Footer />
        </div>
    )
}

export default Services;