import './SectionTitle.css';

const SectionTitle = ({ title, backgroundImage }) => {
    return (
        <div className="section-title">
            <div
                className="background-image"
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + backgroundImage})`
                }}
            ></div>
            <span>{title}</span>
        </div>
    );
}

export default SectionTitle;
