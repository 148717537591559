import React, { useState } from 'react';
import InputField from '../../../components/InputField/InputField';
import OutlineButton from '../../../components/OutlineButton/OutlineButton';
import TextAreaField from '../../../components/TextArea/TextArea';
import './ContactForm.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const ContactForm = () => {
    const { t } = useTranslation(); // useTranslation hook to get translations

    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
        website: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    // Function to validate email format
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Function to validate phone number with country code
    const validatePhoneNumber = (phone) => {
        const phoneRegex = /^\+?[1-9]\d{1,14}$/; // E.164 format for international numbers
        return phoneRegex.test(phone);
    };

    // Function to validate required fields and formats
    const validateForm = () => {
        const { name, email, phone, message } = formData;

        if (!name || !email || !phone || !message) {
            toast.error(t('toast.fillFields'));
            return false;
        }

        if (!validateEmail(email)) {
            toast.warning(t('toast.validateEmail'));
            return false;
        }

        if (!validatePhoneNumber(phone)) {
            toast.warning(t('toast.validatePhone'));
            return false;
        }

        return true;
    };

    const sendEmail = (e) => {
        e.preventDefault();

        // Validate form before sending
        if (!validateForm()) return;

        // Create a copy of formData with only non-empty fields
        const emailData = Object.fromEntries(
            Object.entries(formData).filter(([key, value]) => value.trim() !== '')
        );

        fetch(process.env.REACT_APP_FORMSPREE_ID, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailData),
        })
        .then(response => {
            if (response.ok) {
                // Show success toast
                toast.success(t('toast.successEmail'));

                // Clear the form fields
                setFormData({
                    name: '',
                    company: '',
                    email: '',
                    phone: '',
                    website: '',
                    message: ''
                });

                // Scroll to the top of the page
                window.scrollTo(0, 0);
            } else {
                // Show error toast
                toast.error(t('toast.failedEmail'));
            }
        })
        .catch(error => toast.error(t('toast.error')));
    };

    return (
        <div>
            <form className='contact-form' onSubmit={sendEmail}>
                <InputField 
                    label={t('contact.name')}
                    required 
                    name="name" 
                    onChange={handleChange} 
                    value={formData.name} 
                />
                <InputField 
                    label={t('contact.company')}
                    name="company" 
                    onChange={handleChange} 
                    value={formData.company} 
                />
                <div className='form-item-wrapper'>
                    <InputField 
                        label={t('contact.email')}
                        required 
                        name="email" 
                        onChange={handleChange} 
                        value={formData.email} 
                    />
                    <InputField 
                        label={t('contact.phone')}
                        required 
                        name="phone" 
                        onChange={handleChange} 
                        value={formData.phone} 
                    />
                </div>
                <InputField 
                    label={t('contact.website')} 
                    name="website" 
                    onChange={handleChange} 
                    value={formData.website} 
                />
                <TextAreaField 
                    label={t('contact.message')}
                    name="message" 
                    onChange={handleChange} 
                    value={formData.message} 
                    required
                />
                <div className='submit-button'>
                    <OutlineButton type="submit">
                    {t('contact.submit')}
                    </OutlineButton>
                </div>
            </form>
            
            <ToastContainer />
        </div>
    );
}

export default ContactForm;
