import './Footer.css';

const Footer = () => {
    return (
        <div className='footer-wrapper'>
            &copy; NexGen 2024
        </div>
    )
}

export default Footer;