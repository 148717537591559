import React from 'react';
import './OutlineButton.css';
import { FaArrowRight } from 'react-icons/fa';

const OutlineButton = ({ children, onClick, type = "button", arrow, ...props }) => {
    return (
        <button
            type={type}
            className="outline-button"
            onClick={onClick}
            {...props}
        >
            {children}
            {arrow && <FaArrowRight className="arrow-icon" />}
        </button>
    );
};

export default OutlineButton;
