// data.js


const teamMembers = [
    {
        name: 'Pedro Monteiro',
        role: 'Director of Operations',
        description: 'about.author1Desc',
        imageUrl: '/team/profile.jpeg'
    },
    // Add more team members as needed
];

export default teamMembers;
