import { useTranslation } from 'react-i18next';
import './BackgroundImage.css';
import bg from './bg.png';

const BackgroundImage = () => {
    const { t } = useTranslation(); // useTranslation hook to get translations
    
    return (
        <div className='outside-wrapper'>
            <span className='background-image-title'>{t('home.method')}</span>
            <span className='background-image-text'>{t('home.vision')} <span className='colored-symbol'>+</span> {t('home.creativity')} <span className='colored-symbol'>+</span> {t('home.innovation')} <span className='colored-symbol'>=</span> {t('home.success')}</span>
        </div>
    )
}

export default BackgroundImage;