import { useTranslation } from 'react-i18next';
import Carousel from '../../../../components/Carousel/Carousel';
import './OurClients.css';
import company from './company2.png';

const OurClients = () => {
    const images = [
        company,
        company,
        company,
        company,
        company,
        company,
        company,
        company,
        company,
        company
    ];

    const { t } = useTranslation(); // useTranslation hook to get translations

    return (
        <div className='our-clients-wrapper'>
            <div className='our-clients-title'>
            {t('home.clients')}
            </div>
            <div className='our-clients-carousel'>
            <Carousel images={images} />
            </div>
        </div>
    )
}

export default OurClients;