import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from './logo-black.png';
import logoWhite from './logo-white2.png';
import { MdLightMode } from 'react-icons/md';
import { IoMoonOutline } from "react-icons/io5";
import { motion } from 'framer-motion';
import { useDarkMode } from '../../context/DarkModeContext/darkModeContext';
import { useTranslation } from 'react-i18next';
import LanguageContext from '../../context/LanguageContext/languageContext';
import Switch from 'react-switch';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { darkMode, setDarkMode } = useDarkMode();
    const [rotate, setRotate] = useState(0);
    const { t } = useTranslation();
    const { language, toggleLanguage } = useContext(LanguageContext);
    const navRef = useRef(null); // Create a ref for the navbar

    const handleDarkModeToggle = () => {
        setRotate(darkMode ? 20 : -20);
        setDarkMode(!darkMode);
    };

    const handleLinkClick = () => {
        setIsOpen(false); // Close the menu when a link is clicked
    };

    const handleLanguageSwitch = (checked) => {
        toggleLanguage(checked ? 'en' : 'pt');
    };

    // Handle closing the navbar when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <nav className={`navbar ${darkMode ? 'dark' : ''}`} ref={navRef}>
            <div className="logo-wrapper">
                <img src={darkMode ? logoWhite : logo} alt='logo' className='logo-img' />
            </div>
            <ul className={`nav-items ${isOpen ? 'open' : ''}`}>
                
                <li className="nav-item" onClick={handleLinkClick}><Link to="/">{t('navbar.home')}</Link></li>
                <li className="nav-item" onClick={handleLinkClick}><Link to="/services">{t('navbar.services')}</Link></li>
                <li className="nav-item" onClick={handleLinkClick}><Link to="/products">{t('navbar.products')}</Link></li>
                <li className="nav-item" onClick={handleLinkClick}><Link to="/about">{t('navbar.about')}</Link></li>
                <li className="nav-item" onClick={handleLinkClick}><Link to="/contact">{t('navbar.contact')}</Link></li>
                <li className="nav-item nav-item2">
                    <div className="language-switcher">
                        <span>{language === 'en' ? 'EN' : 'PT'}</span>
                        <Switch
                            onChange={handleLanguageSwitch}
                            checked={language === 'en'}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#012169"
                            offColor="#FF0000"
                            height={12}
                            width={40}
                            handleDiameter={16}
                            onHandleColor="#fff"
                            offHandleColor="#006600"
                        />
                    </div>
                </li>
            </ul>
            <div className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
            </div>
        </nav>
    );
};

export default Navbar;
