import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locale/en/translations.json';
import translationPT from './locale/pt/translations.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  pt: {
    translation: translationPT
  }
};

i18n
  .use(LanguageDetector) // Detects the language
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en', // Fallback language if not detected
    debug: true, // Shows console logs in dev mode
    interpolation: {
      escapeValue: false // React already escapes by default
    }
  });

export default i18n;
