import './MeetUs.css';
import teamMembers from './data.js';
import TeamMember from './TeamMember';
import { useTranslation } from 'react-i18next';

const MeetUs = () => {
    const { t } = useTranslation();

    return (
        <div className='meet-us-wrapper'>
            <p className='meet-us-wrapper-title'>{t('about.meetus')}</p>
            {teamMembers.map((member, index) => (
                <TeamMember key={index} {...member} />
            ))}
        </div>
    )
}

export default MeetUs;