import React from 'react';
import { useTranslation } from 'react-i18next';

const TeamMember = ({ name, role, description, imageUrl }) => {
    const { t } = useTranslation();
    return (
        <div className='team-member-wrapper'>
            <div className='team-member-wrapper-img'>
                <img src={imageUrl} alt='profile img'/>
            </div>
            <p className='team-member-name'>{name}</p>
            <hr className='team-member-line'></hr>
            <p className='team-member-role'>{role}</p>
            <p className='team-member-desc'>{t(description)}</p>
        </div>
    );
}

export default TeamMember;
