import { useTranslation } from 'react-i18next';
import './WhoAreWe.css';
import img from './surprise-box.png';

const WhoAreWe = () => {
    const { t } = useTranslation(); // useTranslation hook to get translations

    return (
        <div className='who-are-we-wrapper' id='who-are-we'>
            <div className='who-are-we-img-wrapper'>
                <img src={img} alt='img left' />
            </div>
            <div className='who-are-we-content'>
            {t('home.quote1')}
            </div>
        </div>
    )
}

export default WhoAreWe;